import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { RichText } from 'prismic-reactjs';
import PageContainer from '../components/pageContainer.js';
import Image from 'gatsby-image';

const JoinUsContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #064f71;
`;

const JoinUsTitle = styled.h1`
  text-align: center;
`;

const JoinUsText = styled.p`
  text-align: center;
  font-size: 1.5rem;
  width: 70%;
  margin: 0 auto;
  line-height: 1.5;
  margin-top: 2rem;
`;

const Images = styled.div`
  display: flex;
`;

const ImageContainer = styled.div`
  flex: 1;
`;

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query {
        want1: file(relativePath: { eq: "want1.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        want2: file(relativePath: { eq: "want2.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        site {
          siteMetadata {
            siteUrl
          }
        }
        prismic {
          allHome_pages {
            edges {
              node {
                title
                description
                seo_image
                seo_imageSharp {
                  childImageSharp {
                    fixed(width: 1024) {
                      ...GatsbyImageSharpFixed_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { node } = data.prismic.allHome_pages.edges.slice(0, 1).pop();
      if (!node) {
        return null;
      }

      const { title, description, seo_imageSharp } = node;

      const meta = [];

      if (seo_imageSharp) {
        meta.push({
          name: `og:image`,
          content:
            data.site.siteMetadata.siteUrl +
            seo_imageSharp.childImageSharp.fixed.src,
        });
      }

      return (
        <Layout>
          <SEO
            title={title ? RichText.asText(title) : null}
            description={description ? RichText.asText(description) : null}
            meta={meta}
          />
          <JoinUsContainer>
            <PageContainer>
              <Images>
                {data.want1 && <ImageContainer><Image fluid={data.want1.childImageSharp.fluid} /></ImageContainer>}
                {data.want2 && <ImageContainer><Image fluid={data.want2.childImageSharp.fluid} /></ImageContainer>}
              </Images>

              <JoinUsTitle>Join us</JoinUsTitle>
              <JoinUsText>
                Apply now ! Send us your resume to {" "}
                <a href={"mailto:jobs@honbo.com"}>jobs@honbo.com</a>
              </JoinUsText>
            </PageContainer>
          </JoinUsContainer>
        </Layout>
      );
    }}
  />
);

export default IndexPage;
